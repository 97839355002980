import './feedbackFormComponent.scss';
import { useEffect } from 'react';
import exclamationMmark from './assets/exclamation-mark.svg'

export default function FeedbackFormComponent() {
	const onSubmit = async (e: any) => {
		e.preventDefault();
		const input1El = (document.getElementsByName('input1')[0] as any);
		const input1 = input1El.value;
		if (input1.length < 1) {
			input1El.classList.add('show-error');
			return;
		}
		else {
			input1El.classList.remove('show-error');
		}
		// 
		const input2El = (document.getElementsByName('input2')[0] as any);
		const input2 = input2El.value;
		if (input2.length < 3) {
			input2El.classList.add('show-error');
			return;
		}
		else {
			input2El.classList.remove('show-error');
		}
		// 
		const input3El = (document.getElementsByName('input3')[0] as any);
		const input3 = input3El.value;
		if (input3.length < 4) {
			input3El.classList.add('show-error');
			return;
		}
		else {
			input3El.classList.remove('show-error');
		}
		// 
		const input4El = (document.getElementsByName('input4')[0] as any);
		const input4 = input4El.files;
		// 
		const checkboxPolicy = (document.getElementById('policy') as any);
		const checkboxPolicyText = (document.getElementById('policy-text') as any);
		if (!checkboxPolicy.checked) {
			checkboxPolicyText.classList.add('text-[#EB5757]')
			return;
		}
		else {
			checkboxPolicyText.classList.remove('text-[#EB5757]')
		}
		//
		const formData = new FormData();
		const title = input1;
		const body = `Кто: ${input1}\nКонтакты: ${input2}\nО проекте: ${input3}`;
		formData.append('title', title);
		formData.append('body', body);
		for (const file of input4) {
			formData.append('files', file, file.name)
		}
		//
		const response = fetch('https://crypto-polygon.ru/Email/SendMsg', {
			method: 'POST',
			body: formData
		}).then(x => {
			if (x.ok) {
				cleanInputs();
				alert('Спасибо за ваше обращение мы скоро свяжемся с вами');
			}
		}).catch(x => {
			alert('Произошла ошибка попробуйте позже.');
		});
	}

	const cleanInputs = () => {
		(document.getElementsByName('input1')[0] as any).value = "";
		(document.getElementsByName('input2')[0] as any).value = "";
		(document.getElementsByName('input3')[0] as any).value = "";
		(document.getElementsByName('input4')[0] as any).value = "";
		(document.getElementsByName('input4')[0] as any).value = null;
		(document.getElementById('policy') as any).checked = false;
	}

	const initFileInputOnchange = () => {
		const inputFileName = document.getElementById('inputFileName');
		const inputFileEmpty = document.getElementById('inputFileEmpty');
		const inputFileFilled = document.getElementById('inputFileFilled');
		document.getElementById('inputFile')!.onchange = function (e: Event) {
			let file = (e.target as HTMLInputElement);
			if (file.files) {
				inputFileName!.innerHTML = file.files![0].name;
				inputFileEmpty!.classList.add('hidden');
				inputFileFilled!.classList.remove('hidden');
			}
		}
	}
	const clearInputFile = () => {
		const inputFile = document.getElementById('inputFile') as HTMLInputElement;
		const inputFileName = document.getElementById('inputFileName');
		const inputFileEmpty = document.getElementById('inputFileEmpty');
		const inputFileFilled = document.getElementById('inputFileFilled');
		inputFile.value = '';
		inputFileEmpty!.classList.remove('hidden');
		inputFileFilled!.classList.add('hidden');
		inputFileName!.innerHTML = 'Прикрепить дополнительную информацию';
	}

	useEffect(() => {
		initFileInputOnchange();
	});

	return <>
		<div className="bg-white/5 rounded-3xl">
			<form className="border border-transparent flex flex-col flex-wrap py-16 px-5 w-[600px] max-w-full mx-auto" onSubmit={(e) => onSubmit(e)}>
				<div className="relative w-full">
					<input name="input1"
						className="w-full relative z-[1] placeholder:text-[#716F88] font-light bg-black border border-transparent hover:border-white/50 focus:border-[#fff] transition-all duration-300 rounded-2xl focus:outline-none text-lg focus:shadow-outline py-4 px-6"
						type="text" placeholder="Как к вам обращаться?" />
					<span className='error text-[#EB5757]'>
						<img src={exclamationMmark} alt="" />
						Заполните поле
					</span>
				</div>
				<div className="relative w-full mt-8">
					<input
						name="input2"
						className="w-full relative z-[1] placeholder:text-[#716F88] font-light bg-black border border-transparent hover:border-white/50 focus:border-[#fff] transition-all duration-300 rounded-2xl focus:outline-none text-lg focus:shadow-outline py-4 px-6 "
						type="text" placeholder="Укажите ваши контакты" />
					<span className='error'>
						<img src={exclamationMmark} alt="" />
						Заполните поле
					</span>
				</div>
				<div className="relative w-full mt-8">
					<textarea
						name="input3"
						className="w-full relative z-[1] placeholder:text-[#716F88] font-light bg-black border border-transparent hover:border-white/50 focus:border-[#fff] transition-all duration-300 rounded-2xl focus:outline-none text-lg focus:shadow-outline py-4 px-6  min-h-[108px]"
						minLength={10} placeholder="Расскажите о проекте или задайте нам вопрос" />
					<span className='error !bottom-5 !h-auto'>
						<img src={exclamationMmark} alt="" />
						Заполните поле
					</span>
				</div>
				<div id='inputFileBlock' className="flex items-center border font-light border-white/[0.15] hover:border-[#7752E0] hover:text-[#7752E0] fill-white hover:fill-[#7752E0] rounded-2xl bg-transparent justify-between text-lg cursor-pointer py-4 px-6 mt-16 transition-all relative">
					<label className='absolute left-0 top-0 h-full w-full cursor-pointer' htmlFor="inputFile"></label>
					<input id='inputFile' name="input4" type="file" multiple />
					<p id='inputFileName'>Прикрепить дополнительную информацию</p>

					<svg id='inputFileEmpty' xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20">
						<path d="M16.5 6.94C16.4896 6.84813 16.4695 6.75763 16.44 6.67V6.58C16.3919 6.47718 16.3278 6.38267 16.25 6.3L10.25 0.3C10.1673 0.222216 10.0728 0.158081 9.97 0.11C9.94015 0.10576 9.90985 0.10576 9.88 0.11C9.77841 0.0517412 9.66622 0.0143442 9.55 0H3.5C2.70435 0 1.94129 0.316071 1.37868 0.87868C0.816071 1.44129 0.5 2.20435 0.5 3V17C0.5 17.7956 0.816071 18.5587 1.37868 19.1213C1.94129 19.6839 2.70435 20 3.5 20H13.5C14.2956 20 15.0587 19.6839 15.6213 19.1213C16.1839 18.5587 16.5 17.7956 16.5 17V7C16.5 7 16.5 7 16.5 6.94ZM10.5 3.41L13.09 6H11.5C11.2348 6 10.9804 5.89464 10.7929 5.70711C10.6054 5.51957 10.5 5.26522 10.5 5V3.41ZM14.5 17C14.5 17.2652 14.3946 17.5196 14.2071 17.7071C14.0196 17.8946 13.7652 18 13.5 18H3.5C3.23478 18 2.98043 17.8946 2.79289 17.7071C2.60536 17.5196 2.5 17.2652 2.5 17V3C2.5 2.73478 2.60536 2.48043 2.79289 2.29289C2.98043 2.10536 3.23478 2 3.5 2H8.5V5C8.5 5.79565 8.81607 6.55871 9.37868 7.12132C9.94129 7.68393 10.7044 8 11.5 8H14.5V17ZM10.5 12H9.5V11C9.5 10.7348 9.39464 10.4804 9.20711 10.2929C9.01957 10.1054 8.76522 10 8.5 10C8.23478 10 7.98043 10.1054 7.79289 10.2929C7.60536 10.4804 7.5 10.7348 7.5 11V12H6.5C6.23478 12 5.98043 12.1054 5.79289 12.2929C5.60536 12.4804 5.5 12.7348 5.5 13C5.5 13.2652 5.60536 13.5196 5.79289 13.7071C5.98043 13.8946 6.23478 14 6.5 14H7.5V15C7.5 15.2652 7.60536 15.5196 7.79289 15.7071C7.98043 15.8946 8.23478 16 8.5 16C8.76522 16 9.01957 15.8946 9.20711 15.7071C9.39464 15.5196 9.5 15.2652 9.5 15V14H10.5C10.7652 14 11.0196 13.8946 11.2071 13.7071C11.3946 13.5196 11.5 13.2652 11.5 13C11.5 12.7348 11.3946 12.4804 11.2071 12.2929C11.0196 12.1054 10.7652 12 10.5 12Z" />
					</svg>

					<div id='inputFileFilled' className='hover:fill-[#7752E0] fill-white h-full aspect-square absolute right-0 top-0 z-[1] flex justify-center items-center hidden' onClick={() => clearInputFile()}>
						<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z" />
						</svg>
					</div>
				</div>

				<div className='mt-16 lg:mx-auto'>
					<button type='submit' className='py-[14px] lg:w-[290px] w-full border border-transparent rounded-2xl bg-[#7752E0] hover:bg-[#7752E0]/80 active:bg-[#3d1b9b] transition-all'>ОТПРАВИТЬ</button>
				</div>
				<label className="mt-16 gap-6 checkbox-style">
					<input className='mr-6' type="checkbox" id="policy" name="policy" />
					<span className="checkmark mt-1"></span>
					<div className='justify-center font-light text-sm'>
						<label id='policy-text' htmlFor="policy">Нажимая кнопку "Отправить", вы подтверждаете, что ознакомились и соглашаетесь с</label> &nbsp;
						<a target='_blank' onClick={() => window.open('/privatepolicy')} className='cursor-pointer text-[#7752E0] hover:underline underline-offset-2'>Политикой конфиденциальности</a>
					</div>
				</label>
			</form>
		</div>
	</>;
}
