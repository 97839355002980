import { useEffect } from "react";
import FeedbackFormComponent from "../../components/feedbackForm/feedbackFormComponent";
import FooterComponent from "../../components/footer/footerComponent";
import HeaderComponent from "../../components/header/headerComponent";
import UniquePlaceComponent from "../../components/uniquePlace/uniquePlaceComponent";
import ResidentInfoComponent from "./components/residentInfo/residentInfoComponent";

export default function ResidentPage() {
	let elementsArray: NodeListOf<Element>;
	function initScroll() {
		elementsArray = document.querySelectorAll(".tile");
		window.addEventListener('scroll', fadeIn);
	}
	function fadeIn() {
		for (var i = 0; i < elementsArray.length; i++) {
			var elem = elementsArray[i]
			var distInView = elem.getBoundingClientRect().top - window.innerHeight + 200;
			if (distInView < 0) {
				elem.classList.add("inView");
			} else {
				elem.classList.remove("inView");
			}
		}
	}
	useEffect(() => {
		initScroll();
		fadeIn();
	});
		

	return <>
		<div className="w-full h-full overflow-hidden bg-grain">
			<div className="container relative">
				<div className="bg-gradients-block">
					<div className="bg-gradient bg-gradient-1"></div>
					<div className="bg-gradient bg-gradient-2"></div>
					<div className="bg-gradient bg-gradient-3"></div>
					<div className="bg-gradient bg-gradient-4"></div>
				</div>
				<HeaderComponent />
				<ResidentInfoComponent />
				<div className='lg:text-[32px] text-xl lg:mb-16 mb-8 lg:mt-32 mt-16'>
					<p>Хочешь присоединиться? Вперед!</p>
				</div>
				<FeedbackFormComponent />
				<UniquePlaceComponent data={["Мы", "очень", "внимательно", "отбираем", "проекты", "и", "резидентов", "криптополигона,", "чтобы", "только", "лучшие", "были", "представлены", "в", "наших", "каталогах"]} />
				<FooterComponent />
			</div>
		</div>
	</>
}