import './menuComponent.scss';
import menuIcon from './assets/menuIcon.svg';
import xIcon from '../../assets/x.svg';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export default function MenuComponent() {
	let navigate = useNavigate();

	const [currentActiveMenu, setCurrentActiveMenu] = useState('');
	const [isShowMenu, setIsShowMenu] = useState(false);
	const routeToPage = (name: string) => {
		navigate(name);
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
		setCurrentActiveMenu(name);
		setIsShowMenu(false);
	}

	return <>
		<div className='menu-component w-full fixed z-10 bottom-5'>
			<div className='hidden lg:flex mx-auto w-fit'>
				<div className="grid grid-cols-3 bg-black rounded-[24px] p-1">
					<button className={'px-6 py-[6px] flex items-center justify-center border border-black rounded-3xl bg-black hover:text-[#7752E0] font-medium text-[16px] transition-all duration-300' + (currentActiveMenu == '' ? ' bg-[#7752E0] !text-white' : '')}
						onClick={() => routeToPage('')}>
						Главная
					</button>
					<button className={'px-6 py-[6px] flex items-center justify-center border border-black rounded-3xl bg-black hover:text-[#7752E0] font-medium text-[16px] transition-all duration-300' + (currentActiveMenu == 'resident' ? ' bg-[#7752E0] !text-white' : '')}
						onClick={() => routeToPage('resident')}>
						Резиденты
					</button>
					<button className={'px-6 py-[6px] flex items-center justify-center border border-black rounded-3xl bg-black hover:text-[#7752E0] font-medium text-[16px] transition-all duration-300' + (currentActiveMenu == 'education' ? ' bg-[#7752E0] !text-white' : '')}
						onClick={() => routeToPage('education')}>
						Обучение
					</button>
					{/* <button className='flex flex-row border border-black rounded-3xl bg-black menu-button'
						style={{ backgroundColor: currentActiveMenu == 'news' ? '#7752E0' : undefined }}
						onClick={() => routeToPage('news')}>
						Новости
					</button> */}
				</div>
				<button className='flex justify-center items-center bg-black h-[48px] w-[48px] ml-4 my-auto p-5 fill-white hover:fill-[#9747FF] transition-all duration-300' style={{ borderRadius: '50%' }} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
					<svg className='scale-150' width="14" height="12" viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
						<path d="M12.7099 5.2899L7.70994 0.289896C7.61484 0.198856 7.50269 0.127491 7.37994 0.0798963C7.13648 -0.0201217 6.8634 -0.0201217 6.61994 0.0798963C6.49719 0.127491 6.38505 0.198856 6.28994 0.289896L1.28994 5.2899C1.1967 5.38313 1.12274 5.49382 1.07228 5.61565C1.02182 5.73747 0.99585 5.86804 0.99585 5.9999C0.99585 6.2662 1.10164 6.52159 1.28994 6.7099C1.47825 6.8982 1.73364 7.00399 1.99994 7.00399C2.26624 7.00399 2.52164 6.8982 2.70994 6.7099L5.99994 3.4099V10.9999C5.99994 11.2651 6.1053 11.5195 6.29283 11.707C6.48037 11.8945 6.73472 11.9999 6.99994 11.9999C7.26516 11.9999 7.51951 11.8945 7.70705 11.707C7.89458 11.5195 7.99994 11.2651 7.99994 10.9999V3.4099L11.2899 6.7099C11.3829 6.80363 11.4935 6.87802 11.6154 6.92879C11.7372 6.97956 11.8679 7.00569 11.9999 7.00569C12.132 7.00569 12.2627 6.97956 12.3845 6.92879C12.5064 6.87802 12.617 6.80363 12.7099 6.7099C12.8037 6.61693 12.8781 6.50633 12.9288 6.38447C12.9796 6.26261 13.0057 6.13191 13.0057 5.9999C13.0057 5.86788 12.9796 5.73718 12.9288 5.61532C12.8781 5.49346 12.8037 5.38286 12.7099 5.2899Z" />
					</svg>
				</button>
			</div>

			<div className='flex lg:hidden flex-col mx-auto w-fit'>
				{
					isShowMenu
						? <>
						<div className="bg-black flex flex-col rounded-[24px] p-1 w-[220px]">
							<button className='border border-black rounded-3xl bg-black py-2 px-4'
								style={{ backgroundColor: currentActiveMenu == '' ? '#7752E0' : undefined }}
								onClick={() => routeToPage('')}>
								Главная
							</button>
							<button className='border border-black rounded-3xl bg-black py-2 px-4'
								style={{ backgroundColor: currentActiveMenu == 'resident' ? '#7752E0' : undefined }}
								onClick={() => routeToPage('resident')}>
								Резиденты
							</button>
							<button className='border border-black rounded-3xl bg-black py-2 px-4'
								style={{ backgroundColor: currentActiveMenu == 'education' ? '#7752E0' : undefined }}
								onClick={() => routeToPage('education')}>
								Обучение
							</button>
							{/* <button className='border border-black rounded-3xl bg-black py-2 px-4'
                                style={{ backgroundColor: currentActiveMenu == 'news' ? '#7752E0' : undefined }}
                                onClick={() => routeToPage('news')}>
                                Новости
                            </button> */}
						</div>
							<div className='inline-block mx-auto mt-5'>
								<button className='bg-black my-auto p-5' style={{ borderRadius: '50%' }}
									onClick={() => setIsShowMenu(false)}>
									<img src={xIcon} style={{ height: '20px', maxHeight: '20px', width: '20px', maxWidth: '20px' }} />
								</button>
							</div>
						</>
						: <>
							<button className='inline bg-black my-auto p-5' style={{ borderRadius: '50%' }}
								onClick={() => setIsShowMenu(true)}>
								<img src={menuIcon} style={{ height: '20px', maxHeight: '20px', width: '20px', maxWidth: '20px' }} />
							</button>
						</>
				}
			</div>
		</div>
	</>;
}