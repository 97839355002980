import LogoComponents from "../logo/logoComponents";
import infoIcon from '../../assets/info.svg';
import { useState } from "react";
import './headerComponent.scss';

export default function HeaderComponent() {

	const [isShowAdditionalInformation, setIsShowAdditionalInformation] = useState(false);
	const onShowAdditionalInformation = () => {
		setIsShowAdditionalInformation(!isShowAdditionalInformation);
	}

	return <>
		<div className="lg:my-12 my-8 flex gap-[20px] lg:flex-row flex-col" style={{ justifyContent: isShowAdditionalInformation ? undefined : 'space-between' /*backgroundColor: isShowAdditionalInformation ? 'rgba(255, 255, 255, 0.05)' : undefined*/ }}>
			<div className="block">
				<LogoComponents />
			</div>
			{
				isShowAdditionalInformation
					? <>
						<div className='flex grow justify-between items-center cursor-pointer ml-16' onClick={() => onShowAdditionalInformation()}>
							<p className='font-light lg:text-xl text-[14px]'>Как стать участником</p>
							<div className="w-[48px] h-[48px] flex justify-center items-center bg-black rounded-full relative z-[1] before:w-[16px] before:h-[2px] before:bg-white before:block hover:before:bg-[#7752E0]">
							</div>
						</div>
					</>
					: <>
						<div className='flex lg:justify-start justify-center items-center lg:text-start text-center'>
							<div className='lg:block hidden h-full bg-[#7752E0] w-[2px] '></div>
							<p className='lg:ml-5 text-xs font-light max-w-[383px]'>
								При поддержке Министерства цифрового развития
								государственного управления, информационных технологий
								и связи Республики Татарстан
							</p>
						</div>
						<div className='flex lg:justify-start justify-center items-center cursor-pointer transition-all duration-300 hover:text-[#7752E0] ' onClick={() => onShowAdditionalInformation()}>
							<img src={infoIcon} height='20' width='20' />
							<p className='underline ml-4 lg:text-xl text-[14px]'>Как стать участником</p>
						</div>
					</>
			}
		</div>
		{
			<div className={"flex flex-col transition-all duration-200 relative font-light" + (isShowAdditionalInformation ? ' max-h-[1000px]' : ' max-h-0 opacity-0 pointer-events-none')}>
				<div className="flex flex-col mt-8" style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: '32px' }}>
					<div className="flex lg:flex-row flex-col w-full ">
						<div className="flex lg:flex-row flex-col">
							<div className="flex px-9 py-5">
								<p className="text-5xl my-auto text-center text-gradient mr-9">1</p>
								<p className="my-auto w-5/6">Свяжитесь с нами. Напишите или позвоните нам</p>
							</div>
							<div className="lg:h-full h-[2px] bg-black lg:w-[2px] w-full"></div>
						</div>
						<div className="flex lg:flex-row flex-col">
							<div className="flex px-9 py-5">
								<p className=" text-5xl my-auto text-center text-gradient mr-9">2</p>
								<p className="my-auto w-5/6">Расскажите нам о своем проекте, идее, команде</p>
							</div>
							<div className="lg:h-full h-[2px] bg-black lg:w-[2px] w-full"></div>
						</div>
						<div className="flex lg:flex-row flex-col">
							<div className="flex px-9 py-5">
								<p className="text-5xl my-auto text-center text-gradient mr-9">3</p>
								<p className="my-auto w-5/6">Комиссия экспертов внимательно оценит проект</p>
							</div>
						</div>
					</div>
					<div className="w-full bg-black" style={{ height: 2 }}></div>
					<div className="flex px-9 py-5">
						<p className=" text-5xl text-center text-gradient mr-9">4</p>
						<p className="my-auto w-5/6">Если проект действительно интересный, перспективный и технологичный, мы обязательно свяжемся с вами для уточнения деталей и включения в группу участников криптополигона</p>
					</div>
				</div>
				<p className="lg:text-[24] text-[16px] text-center py-[64px] mb-[24px]">Мы рады каждому и готовы помогать продвигать перспективные идеи!</p>
				<div className="bg-fix"></div>
			</div>
		}
	</>;
}