import img1 from './assets/img1.webp';
import img2 from './assets/img2.webp';
import img3 from './assets/img3.webp';
import img4 from './assets/img4.webp';
import img5 from './assets/img5.webp';
import img6 from './assets/img6.webp';
import img7 from './assets/img7.webp';
import img8 from './assets/img8.webp';
import './workingSectionComponent.scss';

import { useEffect } from 'react';
//
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
//
import $ from 'jquery';
import 'jquery';

export default function WorkingSectionComponent() {

	const setActiveIcon = (id: string) => {
		document.getElementById('img-' + id)?.setAttribute('class', 'item active');
		document.getElementById('btn-' + id)?.setAttribute('class', 'btn-style border border-white rounded-[22px] bg-transparent py-[9px] px-6 m-[7px] active');
	}
	const setInactiveIcon = (id: string) => {
		document.getElementById('img-' + id)?.setAttribute('class', 'item');
		document.getElementById('btn-' + id)?.setAttribute('class', 'btn-style border border-white rounded-[22px] bg-transparent py-[9px] px-6 m-[7px]');
	}

	function scrollPos(top: any, bottom: any) {
		var parent = $(window).scrollTop();
		var max = bottom - top,
			fraction = parent! - top,
			percent = (fraction / (max / 100)) / 100;
		var pos;

		if (percent >= 0) {
			$('.item').each(function () {
				var itemmax: any = $(this).attr('init-pos');
				pos = (itemmax * (1 - (percent)));
				if (pos < 0) {
					pos = 0;
				}
				$(this).css('transform', 'translateY(' + pos + 'px)');
			})
		}
		//console.log(parent, max, percent, pos, top);
	}

	function init() {
		$('.item').each(function () {
			var value = 0,
				quart = $(window).height()! / 2;
			value = Math.floor(Math.random() * quart);
			if ($(this).hasClass('r-2')) {
				value += quart;
			} else if ($(this).hasClass('r-3')) {
				value += (quart * 2);
			}
			value += quart / 3;
			$(this).css('transform', 'translateY(' + value + 'px)');
			$(this).attr('init-pos', value);
		});
		var wrap = $('.wrap');
		if (wrap != null) {
			var offset = parseInt(wrap.css('top').replace('px', ''));
			var topmost = $('.bigwrap').offset()!.top - $(window).height()!,
				bottommost = $('.bigwrap').offset()!.top + $('.bigwrap').height()! - $('.wrap').height()! - offset - 0; //0 = padding of bigwrap
			$(window).on('scroll', function () {
				scrollPos(topmost, bottommost);
			})

		}
	}

	useEffect(() => {
		init();
		$(window).on('resize', function () {
			$(window).off('scroll');
			init();
		})
	});


	return <>
		<section className="tile flex flex-col w-full">
			<p className="mb-8 lg:mb-16 lg:text-[32px] text-xl font-medium">
				В каких отраслях работает Криптополигон?
			</p>

			<div className="lg:hidden aspect-square">
				<Swiper modules={[Pagination]}
					spaceBetween={32}
					slidesPerView={1}
					pagination={{ clickable: true }}
				>
					<SwiperSlide>
						<div className='flex flex-col mr-2' style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: '32px 8px 8px 32px' }}>
							<img src={img1} draggable="false"
								className='border border-transparent img-style-active'
								style={{ width: '1000px', borderRadius: '32px 8px 8px 32px' }} />
							<p className='my-6 text-sm mx-auto'>
								ИНФОРМАЦИОННЫЕ ТЕХНОЛОГИИ
							</p>
						</div>
					</SwiperSlide>

					<SwiperSlide>
						<div className='flex flex-col mr-2' style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: '8px' }}>
							<img src={img2} draggable="false"
								className='border border-transparent img-style-active'
								style={{ width: '1000px', borderRadius: '8px' }} />
							<p className='my-6 text-sm mx-auto'>
								ПРОМЫШЛЕННОСТЬ
							</p>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='flex flex-col mr-2' style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: '8px' }}>
							<img src={img3} draggable="false"
								className='border border-transparent img-style-active'
								style={{ width: '1000px', borderRadius: '8px' }} />
							<p className='my-6 text-sm mx-auto'>
								ЖКХ
							</p>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='flex flex-col mr-2' style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: '8px' }}>
							<img src={img4} draggable="false"
								className='border border-transparent img-style-active'
								style={{ width: '1000px', borderRadius: '8px' }} />
							<p className='my-6 text-sm mx-auto'>
								СТРОИТЕЛЬСТВО
							</p>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='flex flex-col mr-2' style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: '8px' }}>
							<img src={img5} draggable="false"
								className='border border-transparent img-style-active'
								style={{ width: '1000px', borderRadius: '8px' }} />
							<p className='my-6 text-sm mx-auto'>
								БЛАГОТВОРИТЕЛЬНОСТЬ
							</p>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='flex flex-col mr-2' style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: '8px' }}>
							<img src={img6} draggable="false"
								className='border border-transparent img-style-active'
								style={{ width: '1000px', borderRadius: '8px' }} />
							<p className='my-6 text-sm mx-auto'>
								МЕДИЦИНА
							</p>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='flex flex-col mr-2' style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: '8px' }}>
							<img src={img7} draggable="false"
								className='border border-transparent img-style-active'
								style={{ width: '1000px', borderRadius: '8px' }} />
							<p className='my-6 text-sm mx-auto'>
								СЕЛЬСКОЕ ХОЗЯЙСТВО
							</p>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='flex flex-col mr-2' style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: '8px 32px 32px 8px' }}>
							<img src={img8} draggable="false"
								className='border border-transparent img-style-active'
								style={{ width: '1000px', borderRadius: '8px 32px 32px 8px' }} />
							<p className='my-6 text-sm mx-auto'>
								ФИНАНСЫ И ЭКОНОМИКА
							</p>
						</div>
					</SwiperSlide>
				</Swiper>
			</div>



























			<div className='hidden lg:flex flex-col'>
				<div className='flex flex-wrap mb-16'>
					<button id='btn-1' className='btn-style border border-white rounded-[22px] bg-transparent py-[9px] px-6 m-[7px]'
						onMouseEnter={() => setActiveIcon('1')}
						onMouseLeave={() => setInactiveIcon('1')}
					>
						ИНФОРМАЦИОННЫЕ ТЕХНОЛОГИИ
					</button>
					<button id='btn-2' className='btn-style border border-white rounded-[22px] bg-transparent py-[9px] px-6 m-[7px]'
						onMouseEnter={() => setActiveIcon('2')}
						onMouseLeave={() => setInactiveIcon('2')}
					>
						ПРОМЫШЛЕННОСТЬ
					</button>
					<button id='btn-3' className='btn-style border border-white rounded-[22px] bg-transparent py-[9px] px-6 m-[7px]'
						onMouseEnter={() => setActiveIcon('3')}
						onMouseLeave={() => setInactiveIcon('3')}
					>
						ЖКХ
					</button>
					<button id='btn-4' className='btn-style border border-white rounded-[22px] bg-transparent py-[9px] px-6 m-[7px]'
						onMouseEnter={() => setActiveIcon('4')}
						onMouseLeave={() => setInactiveIcon('4')}
					>
						СТРОИТЕЛЬСТВО
					</button>
					<button id='btn-5' className='btn-style border border-white rounded-[22px] bg-transparent py-[9px] px-6 m-[7px]'
						onMouseEnter={() => setActiveIcon('5')}
						onMouseLeave={() => setInactiveIcon('5')}
					>
						БЛАГОТВОРИТЕЛЬНОСТЬ
					</button>
					<button id='btn-6' className='btn-style border border-white rounded-[22px] bg-transparent py-[9px] px-6 m-[7px]'
						onMouseEnter={() => setActiveIcon('6')}
						onMouseLeave={() => setInactiveIcon('6')}
					>
						МЕДИЦИНА
					</button>
					<button id='btn-7' className='btn-style border border-white rounded-[22px] bg-transparent py-[9px] px-6 m-[7px]'
						onMouseEnter={() => setActiveIcon('7')}
						onMouseLeave={() => setInactiveIcon('7')}
					>
						СЕЛЬСКОЕ ХОЗЯЙСТВО
					</button>
					<button id='btn-8' className='btn-style border border-white rounded-[22px] bg-transparent py-[9px] px-6 m-[7px]'
						onMouseEnter={() => setActiveIcon('8')}
						onMouseLeave={() => setInactiveIcon('8')}
					>
						ФИНАНСЫ И ЭКОНОМИКА
					</button>
				</div>
				<div className="bigwrap">
					<div className='wrap' id='grid-animation'>
						<img id="img-1" className='item r-1' src={img1}
							onMouseEnter={() => setActiveIcon('1')}
							onMouseLeave={() => setInactiveIcon('1')} />
						<img id="img-2" className='item r-1' src={img2}
							onMouseEnter={() => setActiveIcon('2')}
							onMouseLeave={() => setInactiveIcon('2')} />
						<img id="img-3" className='item r-1' src={img3}
							onMouseEnter={() => setActiveIcon('3')}
							onMouseLeave={() => setInactiveIcon('3')} />
						<img id="img-4" className='item r-1' src={img4}
							onMouseEnter={() => setActiveIcon('4')}
							onMouseLeave={() => setInactiveIcon('4')} />
						<img id="img-5" className='item r-2' src={img5}
							onMouseEnter={() => setActiveIcon('5')}
							onMouseLeave={() => setInactiveIcon('5')} />
						<img id="img-6" className='item r-2' src={img6}
							onMouseEnter={() => setActiveIcon('6')}
							onMouseLeave={() => setInactiveIcon('6')} />
						<img id="img-7" className='item r-2' src={img7}
							onMouseEnter={() => setActiveIcon('7')}
							onMouseLeave={() => setInactiveIcon('7')} />
						<img id="img-8" className='item r-2' src={img8}
							onMouseEnter={() => setActiveIcon('8')}
							onMouseLeave={() => setInactiveIcon('8')} />
					</div>
				</div>
			</div>
		</section>
	</>;
}