import './App.scss';
import HomePage from './pages/home/homePage';
import {
	Routes,
	Route,
} from "react-router-dom";
import ResidentPage from './pages/resident/residentPage';
import MenuComponent from './components/menu/menuComponent';
import EducationPage from './pages/education/educationPage';
import PrivatePolicyPage from './pages/privatePolicy/privatePolicyPage';
import IndevPage from './pages/indev/indevPage';


function App() {
	return (
		<>
			<Routes>
				<Route path="/">
					<Route index element={<HomePage />} />
					<Route path='resident' element={<ResidentPage />} />
					<Route path='education' element={<EducationPage />} />
					<Route path='news' element={<ResidentPage />} />
					<Route path='privatepolicy' element={<PrivatePolicyPage />} />
					<Route path='indev' element={<IndevPage />} />
				</Route>
			</Routes>
			<MenuComponent />
		</>
	);
}

export default App;
