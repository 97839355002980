import backgroundDesktopImg from './assets/backgroundDesktop.png';
// import backgroundMobileImg from './assets/backgroundMobile.png';
import img1 from './assets/img1.svg';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.svg';
import img4 from './assets/img4.svg';
import img5 from './assets/img5.svg';
import img6 from './assets/img6.svg';
import img7 from './assets/img7.svg';
import img8 from './assets/img8.svg';

export default function ResidentInfoComponent() {
    return <>
        <div className='grid lg:grid-cols-2 grid-cols-1 gap-5 justify-between bg-cover bg-no-repeat bg-center rounded-3xl xl:px-[100px] px-12 lg:py-[120px] py-8 xl:mx-[-90px] mx-[-20px]' style={{ backgroundImage: `url(${backgroundDesktopImg})` }}>
            <div className='flex flex-col lg:items-start items-center my-auto'>
                <p className='text-white lg:text-[46px] text-[36px] font-medium'>
                    Резиденты
                </p>
                <div className='text-white lg:text-[24px] text-[18px] font-light mt-5 lg:max-w-[450px] lg:text-left text-center'>
                    <p>Уже более 10 проектов размещены в криптополигоне и с каждым днём их количество становится больше.</p>
                </div>
            </div>
            <div className='grid sm:grid-cols-4 grid-cols-2 gap-8 w-full lg:mt-0 mt-[64px]'>
                <InnerCard icon={img1} />
                <InnerCard icon={img2} />
                <InnerCard icon={img3} />
                <InnerCard icon={img4} />
                <InnerCard icon={img5} />
                <InnerCard icon={img6} />
                <InnerCard icon={img7} />
                <InnerCard icon={img8} />
            </div>
        </div>
    </>;
}

function InnerCard(props: { icon: any }) {
    return <div className='border rounded-[32px] border-white/50 w-full h-full flex justify-center items-center aspect-square backdrop-blur-lg opacity-80'
        style={{
            backgroundImage: 'linear-gradient(107.02deg, rgba(255, 255, 255, 0.4) -4.87%, rgba(255, 255, 255, 0) 101.8%),linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))'
        }}>
        <img alt='' className='m-auto' src={props.icon} style={{ height: '40px', minHeight: '40px', width: '40px', minWidth: '40px' }}  />
    </div>;
}