import { useEffect } from 'react';
import './skyMoreComponent.scss';

export default function SkyMoreComponent() {
	var paralaxInit = function () {
		const parallaxElList = Array.from(document.getElementsByClassName('pulse-text') as HTMLCollectionOf<HTMLElement>)
		// Add event listener
		if (window.innerWidth > 1024) {
			document.addEventListener("mousemove", parallax);
		}
		function parallax(e: any) {
			let _mouseX = e.clientX;
			let _mouseY = e.clientY;
			for (let index = 0; index < parallaxElList.length; index++) {
				let ay;
				let ax;
				if (index == 0) {
					ax = ((window.innerWidth / 2) - _mouseX) / (window.innerWidth / 125);
					ay = -((window.innerHeight / 2) - _mouseY) / (window.innerHeight / 125);
				}
				if (index == 1) {
					ax = ((window.innerWidth / 2) - _mouseX) / (window.innerWidth / 125);
					ay = ((window.innerHeight / 2) - _mouseY) / (window.innerHeight / 125);
				}
				if (index == 2) {
					ay = ((window.innerWidth / 2) - _mouseX) / (window.innerWidth / 75);
					ax = -((window.innerHeight / 2) - _mouseY) / (window.innerHeight / 75);
				}
				if (index == 3) {
					ay = -((window.innerWidth / 2) - _mouseX) / (window.innerWidth / 100);
					ax = ((window.innerHeight / 2) - _mouseY) / (window.innerHeight / 100);
				}
				const element = parallaxElList[index];
				element.style.transform = "translate(" + (ax) + 'px,' + (ay) + "px)";
			}
		}
	};
	useEffect(() => {
		paralaxInit();
	});

	return <>
		<section className='tile'>
			<p className='text-[32px] text-xl font-medium mb-20 mt-32'>Криптополигон в цифрах</p>
			<div id="parallax-pulse-text" className="w-full flex flex-col items-start lg:px-7 p-7 lg:h-[652px] pulse-text-container">
				<p className="pulse-text pulse-text-1 self-end xl:mr-[70px] lg:-mb-[33px] mb-[64px] lg:mr-[20px]">
					<span className="number">300</span>
					<span className="text">ВОВЛЕЧЁННЫХ <br /> ЕДИНОМЫШЛЕННИКОВ</span>
				</p>
				<p className="pulse-text pulse-text-2">
					<span className="number">15</span>
					<span className="text">ОТРАСЛЕВЫХ <br /> ВСТРЕЧ</span>
				</p>
				<p className="border-2 border-white rounded-full py-[2px] px-9 lg:text-[64px] text-[32px] font-extralight md:self-center self-end lg:m-auto my-12">БОЛЕЕ</p>
				<p className="pulse-text pulse-text-3 xl:ml-[80px] lg:ml-[20px] lg:-mb-[33px] mb-[64px]" style={{ backgroundColor: 'rgba(119, 82, 224, 1)' }}>
					<span className="number">200</span>
					<span className="text">М<sup>2</sup> ПРОСТРАНСТВА<br />В ЦЕНТРЕ КАЗАНИ</span>
				</p>
				<p className="pulse-text pulse-text-4 self-end ">
					<span className="number">10</span>
					<span className="text">АКТИВНЫХ<br />ПРОЕКТОВ</span>
				</p>

			</div>
		</section>
	</>;
}