import tgLogo from '../../assets/tgLogo.svg';
import phoneLogo from '../../assets/phone.svg';
import addressLogo from '../../assets/address.svg';
import './footerComponent.scss';

export default function FooterComponent() {
    return <>
        <section className='tile grid lg:grid-cols-2 grid-cols-1 gap-8 justify-between mb-16 lg:mt-[64px] mt-[32px] font-light relative'>
            <div className='tg-link-hover flex lg:flex-row flex-col justify-between lg:items-start items-center rounded-3xl p-7 lg:gap-0 gap-16 relative' style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)' }}>
				<a href='https://t.me/crypto_polygon' target="_blank" className='absolute z-[1] left-0 top-0 w-full h-full'></a>
                <div className='flex flex-col justify-between lg:items-start items-center h-full'>
                    <p className='text-xl max-w-[241px] '>Вступай в наши ряды и будь в курсе событий</p>
                    <a href='https://t.me/crypto_polygon' target="_blank" className='tg-link-text lg:block hidden underline text-xl underline-offset-4 transition-all'>Посетить телеграм-канал</a>
                </div>
                <img className='tg-link-icon max-w-[144px] lg:mx-auto my-auto' src={tgLogo} />
				<p className='tg-link-text lg:hidden block underline text-xl underline-offset-4 transition-all'>Посетить телеграм-канал</p>
            </div>

            <div className='flex flex-col gap-6 rounded-3xl p-7' style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)' }}>
                <p className='text-xl font-medium'>КОНТАКТЫ</p>
                <div className='flex'>
                    <img className='mr-6' src={phoneLogo} />
                    <a href="tel:+79372842747" className='text-xl hover:text-[#7752E0] transition-all'>+7 937 284 2747</a>
                </div>
                <div className='flex items-start'>
                    <img className='mr-6 mt-[1px]' src={addressLogo} />
                    <a href="https://go.2gis.com/531eol" target="_blank" className='text-xl hover:text-[#7752E0] transition-all'>г. Казань, ул. Спартаковская, д.2, <br /> ИТ-парк им. Башира Рамеева</a>
                </div>
            </div>
        </section>
    </>;
}