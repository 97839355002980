import FooterComponent from "../../components/footer/footerComponent";
import HeaderComponent from "../../components/header/headerComponent";
import backgroundDesktopImg from "./assets/backgroundDesktop.png";
// import backgroundMobileImg from "./assets/backgroundMobile.png";
import arrowRightImg from "./assets/arrowRight.svg";
import { useEffect } from "react";

export default function EducationPage() {
	let elementsArray: NodeListOf<Element>;
	function initScroll() {
		elementsArray = document.querySelectorAll(".tile");
		window.addEventListener('scroll', fadeIn);
	}
	function fadeIn() {
		for (var i = 0; i < elementsArray.length; i++) {
			var elem = elementsArray[i]
			var distInView = elem.getBoundingClientRect().top - window.innerHeight + 200;
			if (distInView < 0) {
				elem.classList.add("inView");
			} else {
				elem.classList.remove("inView");
			}
		}
	}
	var paralaxInit = function () {
		const parallaxElList = Array.from(document.getElementsByClassName('bg-gradient') as HTMLCollectionOf<HTMLElement>)
		// Add event listener
		document.addEventListener("mousemove", parallax);
		function parallax(e: any) {
			let _mouseX = e.clientX;
			let _mouseY = e.clientY;
			for (let index = 0; index < parallaxElList.length; index++) {
				let ay;
				let ax;
				if (index == 0) {
					ax = ((window.innerWidth / 2) - _mouseX) / (window.innerWidth / 125);
					ay = -((window.innerHeight / 2) - _mouseY) / (window.innerHeight / 125);
				}
				if (index == 1) {
					ax = ((window.innerWidth / 2) - _mouseX) / (window.innerWidth / 125);
					ay = ((window.innerHeight / 2) - _mouseY) / (window.innerHeight / 125);
				}
				if (index == 2) {
					ay = ((window.innerWidth / 2) - _mouseX) / (window.innerWidth / 75);
					ax = -((window.innerHeight / 2) - _mouseY) / (window.innerHeight / 75);
				}
				if (index == 3) {
					ay = -((window.innerWidth / 2) - _mouseX) / (window.innerWidth / 100);
					ax = ((window.innerHeight / 2) - _mouseY) / (window.innerHeight / 100);
				}
				const element = parallaxElList[index];
				element.style.transform = "translate(" + (ax) + 'px,' + (ay) + "px)";
			}
		}
	};
	useEffect(() => {
		initScroll();
		fadeIn();
		paralaxInit();
	});
	return <>
		<div className="w-full h-full overflow-hidden bg-grain">
			<div className="container relative">
				<div className="bg-gradients-block">
					<div className="bg-gradient bg-gradient-1"></div>
					<div className="bg-gradient bg-gradient-2"></div>
					<div className="bg-gradient bg-gradient-3"></div>
					<div className="bg-gradient bg-gradient-4"></div>
				</div>
				<HeaderComponent />
				<div className="grid lg:grid-cols-[3fr,2fr] grid-cols-1 lg:gap-8 gap-5 lg:mb-[250px] mb-[128px]">
					<div className="flex flex-col lg:items-start items-center justify-center gap-8 border border-transparent rounded-3xl lg:py-[90px] lg:px-[64px] py-[32px] px-[20px] bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${backgroundDesktopImg})` }}>
						<p className="lg:text-[46px] text-[36px]">
							Обучение
						</p>
						<p className="lg:text-[24px] text-[18px] font-light lg:text-left text-center lg:leading-[30px] leading-[22px]">
							Если хотите пройти обучение по работе с криптовалютой или разобраться в технологиях работы с блокчейн, переходите на специальный обучающий портал, который создан экспертами криптополигона при поддержке Минцифры РТ и ИТ-парка.
						</p>
					</div>
					<div className="flex flex-col">
						<div className="inline-block text-3xl border border-transparent rounded-3xl p-8" style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)' }}>
							<h2 className="lg:text-[32px] lg:leading-10 leading-7 text-xl font-medium">
								Начни изучать самые перспективные направления и стань успешным!
							</h2>
							<button className="py-6 px-8 border border-transparent rounded-3xl w-full mt-8 flex justify-between items-center bg-[#7752E0] hover:bg-[#7752E0]/80 active:bg-[#3d1b9b] transition-all duration-300 font-medium" onClick={() => window.open("/indev")}>
								<p className="text-lg" >ПЕРЕЙТИ</p>
								<img src={arrowRightImg} />
							</button>
						</div>
						<div className="inline-block text-lg border border-transparent rounded-3xl p-8 mt-8" style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)' }}>
							<p className="lg:text-[18px] text-[16px] font-light lg:text-left text-center leading-[1.3em]">Там вы сможете погрузиться в океан знаний, которые мы тщательно адаптировали под различные группы учеников.</p>
						</div>
					</div>
				</div>
				<FooterComponent />
			</div>
		</div>
	</>;
}