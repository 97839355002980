import CryptoPolygonComponent from './components/cryptoPolygon/cryptoPolygonComponent';
import './homePage.scss';
import UniquePlaceComponent from '../../components/uniquePlace/uniquePlaceComponent';
import WorkingSectionComponent from './components/workingSection/workingSectionComponent';
import FeedbackFormComponent from '../../components/feedbackForm/feedbackFormComponent';
import SliderComponent from './components/slider/sliderComponent';
import SkyMoreComponent from './components/skyMore/skyMoreComponent';
import HeaderComponent from '../../components/header/headerComponent';
import FooterComponent from '../../components/footer/footerComponent';
import { useEffect } from 'react';

export default function HomePage() {
	let elementsArray: NodeListOf<Element>;
	function initScroll() {
		elementsArray = document.querySelectorAll(".tile");
		window.addEventListener('scroll', fadeIn);
	}
	function fadeIn() {
		for (var i = 0; i < elementsArray.length; i++) {
			var elem = elementsArray[i]
			var distInView = elem.getBoundingClientRect().top - window.innerHeight + 200;
			if (distInView < 0) {
				elem.classList.add("inView");
			} else {
				elem.classList.remove("inView");
			}
		}
	}
	var paralaxInit = function () {
		const parallaxElList = Array.from(document.getElementsByClassName('bg-gradient') as HTMLCollectionOf<HTMLElement>)
		// Add event listener
		document.addEventListener("mousemove", parallax);
		function parallax(e: any) {
			let _mouseX = e.clientX;
			let _mouseY = e.clientY;
			for (let index = 0; index < parallaxElList.length; index++) {
				let ay;
				let ax;
				if (index == 0) {
					ax = ((window.innerWidth / 2) - _mouseX) / (window.innerWidth / 125);
					ay = -((window.innerHeight / 2) - _mouseY) / (window.innerHeight / 125);
				}
				if (index == 1) {
					ax = ((window.innerWidth / 2) - _mouseX) / (window.innerWidth / 125);
					ay = ((window.innerHeight / 2) - _mouseY) / (window.innerHeight / 125);
				}
				if (index == 2) {
					ay = ((window.innerWidth / 2) - _mouseX) / (window.innerWidth / 75);
					ax = -((window.innerHeight / 2) - _mouseY) / (window.innerHeight / 75);
				}
				if (index == 3) {
					ay = -((window.innerWidth / 2) - _mouseX) / (window.innerWidth / 100);
					ax = ((window.innerHeight / 2) - _mouseY) / (window.innerHeight / 100);
				}
				const element = parallaxElList[index];
				element.style.transform = "translate(" + (ax) + 'px,' + (ay) + "px)";
			}
		}
	};
	var paralaxInit = function () {
		const parallaxElList = Array.from(document.getElementsByClassName('bg-gradient') as HTMLCollectionOf<HTMLElement>)
		// Add event listener
		document.addEventListener("mousemove", parallax);
		function parallax(e: any) {
			let _mouseX = e.clientX;
			let _mouseY = e.clientY;
			for (let index = 0; index < parallaxElList.length; index++) {
				let ay;
				let ax;
				if (index == 0) {
					ax = ((window.innerWidth / 2) - _mouseX) / (window.innerWidth / 125 * 1.5);
					ay = -((window.innerHeight / 2) - _mouseY) / (window.innerHeight / 125 * 1.5);
				}
				if (index == 1) {
					ax = ((window.innerWidth / 2) - _mouseX) / (window.innerWidth / 125 * 1.5);
					ay = ((window.innerHeight / 2) - _mouseY) / (window.innerHeight / 125 * 1.5);
				}
				if (index == 2) {
					ay = ((window.innerWidth / 2) - _mouseX) / (window.innerWidth / 75 * 1.5);
					ax = -((window.innerHeight / 2) - _mouseY) / (window.innerHeight / 75 * 1.5);
				}
				if (index == 3) {
					ay = -((window.innerWidth / 2) - _mouseX) / (window.innerWidth / 100 * 1.5);
					ax = ((window.innerHeight / 2) - _mouseY) / (window.innerHeight / 100 * 1.5);
				}
				const element = parallaxElList[index];
				element.style.transform = "translate(" + (ax) + 'px,' + (ay) + "px)";
			}
		}
	};
	useEffect(() => {
		initScroll();
		fadeIn();
		paralaxInit();
	});
	return <>
		<div className="w-full h-full overflow-hidden bg-grain" >
			<div className="container relative">
				<div className="bg-gradients-block">
					<div className="bg-gradient bg-gradient-1"></div>
					<div className="bg-gradient bg-gradient-2"></div>
					<div className="bg-gradient bg-gradient-3"></div>
					<div className="bg-gradient bg-gradient-4"></div>
				</div>
				<HeaderComponent />
				<CryptoPolygonComponent />
				<SliderComponent />
				<UniquePlaceComponent data={["Уникальное", "пространство,", "где", "каждый", "может", "погрузиться", "в", "мир", "новых", "технологий,", "которые", "меняют", "привычный", "порядок", "вещей"]} />
				<WorkingSectionComponent />
				<SkyMoreComponent />
				<section className='tile'>
					<h2 className='lg:text-[32px] text-xl font-medium lg:leading-10 mb-16 mt-32'>
						<p>Есть достойный проект? Напиши нам</p>
						<p>и стань участником уникального сообщества!</p>
					</h2>
					<FeedbackFormComponent />
				</section>
				<FooterComponent />
			</div>
		</div>
	</>;
}