import './cryptoPolygonComponent.scss'
import backgroundImg from './assets/background.webp';
// import backgroundMobileImg from './assets/backgroundMobile.png';
import ellipseIcon from './assets/ellipse.png';
import rectangleIcon from './assets/rectangle.png';
import triangleIcon from './assets/triangle.png';
import starIcon from './assets/star.png';

export default function CryptoPolygonComponent() {
	return <>
		<div className='grid xl:grid-cols-2 grid-cols-1 justify-between xl:gap-5 gap-16 bg-cover bg-no-repeat rounded-[32px] xl:px-20 px-5 xl:py-12 pt-12 pb-5 xl:mx-[-90px] mx-[-20px] home-bg-fix' style={{ backgroundImage: `url(${backgroundImg})` }}>
			<Content />
		</div>
	</>;
}

function Content() {
	return <>
		<div className='flex flex-col justify-center xl:mx-0 mx-auto'>
			<p className='text-white lg:text-[46px] text-4xl font-medium'>Криптополигон</p>
			<p className='text-white lg:text-2xl text-lg mt-5 font-light lg:max-w-[414px] max-w-[320px] xl:text-left text-center'>Центр притяжения знаний <br /> и технологий в криптоиндустрии</p>
		</div>
		
		<div className='grid gap-8 lg:grid-cols-2 lg:grid-rows-[166px,166px] auto-rows-fr relative z-[1]'>
			<InnerCard title='Криптоуниверситет' icon={ellipseIcon} />
			<InnerCard title='Акселерация криптопроектов' icon={triangleIcon} />
			<InnerCard title='Испытание новых технологий' icon={rectangleIcon} />
			<InnerCard title='Апробирование законодательных инициатив' icon={starIcon} />
		</div>
	</>
}

function InnerCard(props: { title: string, icon: any }) {
	return <div className='inner-card flex flex-col justify-between p-6 border border-[#ffffff1a] rounded-2xl w-full h-full backdrop-blur-[15px] relative overflow-hidden'>
		<p className='text-white text-xl font-medium'>{props.title}</p>
		<img className='ml-auto lg:mt-0 mt-2' src={props.icon} height='40' width='40' />
	</div>;
}