import square from "./assets/square.svg";
import square_gradient from "./assets/square-gradient.png";
import triangle from "./assets/triangle.svg";
import triangle_gradient from "./assets/triangle-gradient.png";
import circle from "./assets/circle.svg";
import circle_gradient from "./assets/circle-gradient.png";
import star from "./assets/star.svg";
import bubble from "./assets/bubble.svg";
import bubble_gradient from "./assets/bubble-gradient.png";

import './sliderComponent.scss';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function SliderComponent() {
	return <>
		<section className='tile bottom-right mt-16 lg:mt-32 lg:mr-[-112px] flex w-full slider-component'>
			<div className="md:flex flex-col hidden w-full">
				<h2 className="lg:text-[32px] text-xl font-medium mb-8 lg:mb-16 ">Тебе нужно в Криптополигон, если:</h2>

				<div className="flex relative w-full slider-desktop h-[320px]">
					<Swiper modules={[Scrollbar]}
						spaceBetween={32}
						slidesPerView={'auto'}
						scrollbar={{ draggable: true, dragSize: 58 }}>
						<SwiperSlide>
							<div className="flex flex-col items-start rounded-3xl pt-14 px-7 pb-7 cursor-pointer slider-item min-w-[382px] w-[382px] h-[260px] bg-white/5">
								<div className="figure-block">
									<img className="dots" src={circle} height="80" width="80" />
									<img className="gradient" src={circle_gradient} height="80" width="80" />
								</div>
								<p className="mt-auto font-light">Хочешь научиться работать с частными цифровыми валютами</p>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="flex flex-col items-start rounded-3xl pt-14 px-7 pb-7 cursor-pointer slider-item min-w-[382px] w-[382px] h-[260px] bg-white/5">
								<div className="figure-block">
									<img className="dots" src={square} height="80" width="80" />
									<img className="gradient" src={square_gradient} height="80" width="80" />
								</div>
								<p className="mt-auto font-light">Знаешь программирование и хочешь разрабатывать блокчейн проекты</p>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="flex flex-col items-start rounded-3xl pt-14 px-7 pb-7 cursor-pointer slider-item min-w-[382px] w-[382px] h-[260px] bg-white/5">
								<div className="figure-block">
									<img className="dots" src={triangle} height="80" width="80" />
									<img className="gradient min-w-[80px]" src={triangle_gradient} height="80" width="80" />
								</div>
								<p className="mt-auto font-light">У тебя есть проект, который имеет потенциал роста</p>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="flex flex-col items-start rounded-3xl pt-14 px-7 pb-7 cursor-pointer slider-item min-w-[382px] w-[382px] h-[260px] bg-white/5">
								<div className="figure-block">
									<img className="dots" src={triangle} height="80" width="80" />
									<img className="gradient" src={circle_gradient} height="80" width="80" />
								</div>
								<p className="mt-auto font-light">Ты ищешь инвесторов <br /> для своего стартапа</p>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="flex flex-col items-start rounded-3xl pt-14 px-7 pb-7 cursor-pointer slider-item min-w-[382px] w-[382px] h-[260px] bg-white/5">
								<div className="figure-block">
									<img className="dots" src={star} height="80" width="80" />
									<img className="gradient" src={circle_gradient} height="80" width="80" />
								</div>
								<p className="mt-auto font-light">Есть крутая идея <br /> в криптоиндустрии</p>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="flex flex-col items-start rounded-3xl pt-14 px-7 pb-7 cursor-pointer slider-item min-w-[382px] w-[382px] h-[260px] bg-white/5">
								<div className="figure-block">
									<img className="dots" src={bubble} height="80" width="80" />
									<img className="gradient" src={bubble_gradient} height="80" width="80" />
								</div>
								<p className="mt-auto font-light">Хочешь пообщаться <br /> с единомышленниками</p>
							</div>
						</SwiperSlide>
					</Swiper>
				</div>
			</div>

			<div className="md:hidden w-full h-[280px]">
				<Swiper
					modules={[Navigation, Pagination, Scrollbar, A11y]}
					spaceBetween={50}
					slidesPerView={1}
					pagination={{ clickable: true }}
				>
					<SwiperSlide>
						<div className="flex flex-col items-start rounded-3xl lg:mr-3 pt-14 px-7 pb-7 cursor-pointer slider-item bg-white/5">
							<div className="figure-block">
								<img className="dots" src={circle} />
								<img className="gradient" src={circle_gradient} />
							</div>
							<p className="mt-14 font-light">Хочешь научиться работать с частными цифровыми валютами</p>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="flex flex-col items-start rounded-3xl lg:mr-3 pt-14 px-7 pb-7 cursor-pointer slider-item bg-white/5">
							<div className="figure-block">
								<img className="dots" src={square} />
								<img className="gradient" src={square_gradient} />
							</div>
							<p className="mt-14 font-light">Знаешь программирование и хочешь разрабатывать блокчейн проекты</p>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="flex flex-col items-start rounded-3xl lg:mr-3 pt-14 px-7 pb-7 cursor-pointer slider-item bg-white/5">
							<div className="figure-block">
								<img className="dots" src={triangle} />
								<img className="gradient" src={triangle_gradient} />
							</div>
							<p className="mt-14 font-light">У тебя есть проект, который имеет потенциал роста</p>
						</div>
					</SwiperSlide>
					<SwiperSlide>

						<div className="flex flex-col items-start rounded-3xl lg:mr-3 pt-14 px-7 pb-7 cursor-pointer slider-item bg-white/5">
							<div className="figure-block">
								<img className="dots" src={triangle} />
								<img className="gradient" src={circle_gradient} />
							</div>
							<p className="mt-14 font-light">Ты ищешь инвесторов для своего стартапа</p>
						</div>
					</SwiperSlide>

					<SwiperSlide>
						<div className="flex flex-col items-start rounded-3xl lg:mr-3 pt-14 px-7 pb-7 cursor-pointer slider-item bg-white/5">
							<div className="figure-block">
								<img className="dots" src={star} />
								<img className="gradient" src={circle_gradient} />
							</div>
							<p className="mt-14 font-light">Есть крутая идея в криптоиндустрии</p>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="flex flex-col items-start rounded-3xl lg:mr-3 pt-14 px-7 pb-7 cursor-pointer slider-item bg-white/5">
							<div className="figure-block">
								<img className="dots" src={bubble} />
								<img className="gradient" src={bubble_gradient} />
							</div>
							<p className="mt-14 font-light">Хочешь пообщаться с единомышленниками</p>
						</div>
					</SwiperSlide>
				</Swiper>
			</div>
		</section>
	</>;
}