import LogoComponents from "../../components/logo/logoComponents";
import gradient1 from "./assets/1.svg";
import gradient2 from "./assets/2.svg";
import  "./indevPage.scss";



export default function IndevPage() {
	return <>
		<div className="w-full min-h-[100vh] flex flex-col overflow-hidden relative bg-grain bg-[#000] education-indev-page">
			<img className="absolute top-0 left-0 max-w-[75%]" src={gradient1} alt="" />
			<img className="absolute bottom-0 right-0 max-w-[75%]" src={gradient2} alt="" />

			<div className="container relative h-full flex flex-col flex-1">
				<div className="lg:mt-12 mt-8 flex gap-[20px] lg:flex-row flex-col">
					<div className="block">
						<LogoComponents />
					</div>
					<div className='flex lg:justify-start justify-center items-center lg:text-start text-center lg:ml-auto'>
						<div className='lg:block hidden h-full bg-[#7752E0] w-[2px]'></div>
						<p className='lg:ml-5 text-xs font-light max-w-[383px]'>
							При поддержке Министерства цифрового развития
							государственного управления, информационных технологий
							и связи Республики Татарстан
						</p>
					</div>
				</div>
				<div className="m-auto w-[773px] max-w-full flex flex-col items-center text-center lg:py-12 py-8 relative">
					<h1 className="lg:text-[46px] text-[32px] font-medium mb-[20px] pt-[54px] lg:leading-[56px] leading-[40px]">Мы уже работаем над запуском образовательной платформы</h1>
					<p className="lg:text-[24px] text-[18px] font-light mb-[64px]">Скоро каждый сможет пройти обучение и стать экспертом <span className="whitespace-nowrap"> в криптомире.</span> Следите за новостями в телеграм-канале:</p>
					<a href='https://t.me/crypto_polygon' target="_blank" className='py-[14px] px-[32px] mx-auto flex justify-between items-center sm:w-[360px] w-full border border-transparent rounded-3xl bg-[#7752E0] hover:bg-[#5125cf] active:bg-[#3d1b9b] transition-all duration-300'>
						ТЕЛЕГРАМ-КАНАЛ
						<svg width="48" height="12" viewBox="0 0 48 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M48 6L38 0.226494L38 11.7735L48 6ZM8.74228e-08 7L39 7L39 5L-8.74228e-08 5L8.74228e-08 7Z" fill="white" />
						</svg>
					</a>
				</div>


			</div>
		</div>
	</>;
}