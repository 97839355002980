import { gsap } from 'gsap';

import React, { useEffect } from 'react';
import borderIcon from './assets/border.svg';
import './uniquePlaceComponent.scss';

export default function UniquePlaceComponent(props: { data: string[] }) {
	var paralaxInit = function () {
		if (window.innerWidth > 1024) {
			const el = document.querySelectorAll('.perspective-effect');
			const halfX = window.innerWidth / 2;
			const halfY = window.innerHeight / 2;
			el.forEach((el, i) => {
				gsap.to(el, 1, {
					z: 1 * (i + 8)
				});
			});
			document.addEventListener('mousemove', (e) => {
				el.forEach((el, i) => {
					gsap.to(el, 0.5, {
						x: (e.clientX - halfX) * (i + 1) * 0.01,
						y: (e.clientY - halfY) * (i + 1) * 0.01
					});
				});
	
			});
		}
	};
	useEffect(() => {
		paralaxInit();
	});

	return <>
		<section id='parallax' className='tile flex justify-between lg:my-[256px] my-[128px] text-center w-full relative'>
			<div className='lg:flex hidden flex-col justify-between relative w-[40px] h-[376px]'>
				<div className="absolute h-full w-full left-0 top-0 flex flex-col justify-between">
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' />
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(270deg)' }} />
				</div>
				<div className="absolute h-full w-full left-0 top-0 flex flex-col justify-between">
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' />
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(270deg)' }} />
				</div>
				<div className="absolute h-full w-full left-0 top-0 flex flex-col justify-between">
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' />
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(270deg)' }} />
				</div>
				<div className="absolute h-full w-full left-0 top-0 flex flex-col justify-between">
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' />
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(270deg)' }} />
				</div>
				<div className="absolute h-full w-full left-0 top-0 flex flex-col justify-between">
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' />
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(270deg)' }} />
				</div>
				<div className="absolute h-full w-full left-0 top-0 flex flex-col justify-between">
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' />
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(270deg)' }} />
				</div>
				<div className="absolute h-full w-full left-0 top-0 flex flex-col justify-between">
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' />
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(270deg)' }} />
				</div>
				<div className="absolute h-full w-full left-0 top-0 flex flex-col justify-between">
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' />
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(270deg)' }} />
				</div>
			</div>
			<div className='lg:hidden flex flex-col justify-between'>
				<img className='max-w-none' src={borderIcon} height='40' width='40' />
				<img className='max-w-none' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(270deg)' }} />
			</div>
			<div className="my-auto lg:py-[80px] py-[28px] lg:text-[44px] text-base gradient-text safari-fix w-full flex justify-center flex-wrap max-w-[1060px] lg:leading-[54px] perspective-effect">
				{
					props.data.map((value, index) => {
						return <React.Fragment key={index}>
							<p className="safari-fix" title={value}>
								{value}
							</p>
							{
								index != (props.data.length - 1) && <>
									&nbsp;
								</>
							}
						</React.Fragment>
					})
				}
			</div>
			<div className='lg:flex hidden flex-col justify-between relative w-[40px] h-[376px]'>
				<div className="absolute h-full w-full left-0 top-0 flex flex-col justify-between">
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(90deg)' }} />
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(180deg)' }} />
				</div>
				<div className="absolute h-full w-full left-0 top-0 flex flex-col justify-between">
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(90deg)' }} />
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(180deg)' }} />
				</div>
				<div className="absolute h-full w-full left-0 top-0 flex flex-col justify-between">
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(90deg)' }} />
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(180deg)' }} />
				</div>
				<div className="absolute h-full w-full left-0 top-0 flex flex-col justify-between">
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(90deg)' }} />
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(180deg)' }} />
				</div>
				<div className="absolute h-full w-full left-0 top-0 flex flex-col justify-between">
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(90deg)' }} />
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(180deg)' }} />
				</div>
				<div className="absolute h-full w-full left-0 top-0 flex flex-col justify-between">
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(90deg)' }} />
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(180deg)' }} />
				</div>
				<div className="absolute h-full w-full left-0 top-0 flex flex-col justify-between">
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(90deg)' }} />
					<img className='max-w-none perspective-effect' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(180deg)' }} />
				</div>
			</div>
			<div className='lg:hidden flex flex-col justify-between'>
				<img className='max-w-none' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(90deg)' }} />
				<img className='max-w-none' src={borderIcon} height='40' width='40' style={{ transform: 'rotate(180deg)' }} />
			</div>
		</section>
	</>;
}